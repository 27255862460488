import moment from 'moment';
import { useFormikContext } from 'formik';
import { Column, Row, Input, Paragraph, Select } from 'src/components';
import { Patient } from 'src/types';
import { theme, addPatientValidation } from 'src/utils';
import { ModalSubTitle } from '../styled';


interface FormProps {
  isEditPatient: boolean;
};

type AddPatientValidation = typeof addPatientValidation;

const BasicInfo: React.FC<FormProps> = ({ isEditPatient }) => {

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    errors
  } = useFormikContext<Patient>();

  const { basicInfo } = values;
  const { basicInfo: touch } = touched;
  const err = errors as AddPatientValidation; 
  const details = ['Single', 'Married', 'Divorced', 'Widow(er)', 'Separated', 'Engaged', 'Others']

  return (
    <Column justify="space-between" gap={1} align="center">
      <Column>
        <ModalSubTitle data-testid="section-title">Basic Information</ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          Fill in the user information, all * fields are required
        </Paragraph>
      </Column>
      <Row gap={1}>
        <Column gap={1} justify="space-between">
          <Input
            onChange={event => setFieldValue("basicInfo.firstName", event.target.value.trim())}
            onBlur={handleBlur}
            label="First Name"
            placeholder="Patient's First Name"
            name="basicInfo.firstName"
            type="text"
            autoFocus
            required
            value={basicInfo.firstName?.trim()}
            error={touch?.firstName ? err['basicInfo.firstName'] : ""}
          />
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            label="Phone Number"
            placeholder="E.g. 0801 234 5678"
            name="basicInfo.phoneNumber"
            disabled={isEditPatient}
            required
            type="text"
            value={basicInfo.phoneNumber?.replace(/\D/g, "")}
            error={touch?.phoneNumber ? err['basicInfo.phoneNumber'] : ""}
          />
          {isEditPatient && (
            <>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                required
                label="Date of Birth"
                placeholder="DD-MM-YYYY"
                name="basicInfo.dateOfBirth"
                max={moment().format('YYYY-MM-DD')}
                type="date"
                value={basicInfo.dateOfBirth}
                error={touch?.dateOfBirth ? err['basicInfo.dateOfBirth'] : ""}
              />
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                label="Marital Status"
                required
                name="basicInfo.maritalStatus"
                value={basicInfo.maritalStatus}
                error={touch?.maritalStatus ? err['basicInfo.maritalStatus'] : ""}
              >
                <option disabled value="">Choose Option</option>
                {details?.map((val, idx) => (
                  <option key={idx} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
            </>
          )}
        </Column>
        <Column gap={1} justify="space-between">
          <Input
            onChange={event => setFieldValue("basicInfo.lastName", event.target.value.trim())}
            onBlur={handleBlur}
            label="Last Name"
            placeholder="Patient's Last Name"
            name="basicInfo.lastName"
            type="text"
            required
            value={basicInfo.lastName?.trim()}
            error={touch?.lastName ? err['basicInfo.lastName'] : ""}
          />
          <Input
            onChange={(event) => setFieldValue("basicInfo.emailAddress", event.target.value.trim())}
            onBlur={handleBlur}
            label="Email Address"
            placeholder="marvin@acme.com"
            name="basicInfo.emailAddress"
            type="text"
            value={basicInfo.emailAddress?.trim()}
            disabled={isEditPatient}
            error={touch?.emailAddress ? err['basicInfo.emailAddress'] : ""}
          />
          {isEditPatient && (
            <>
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                label="Gender"
                name="basicInfo.gender"
                value={basicInfo.gender}
                required
                error={touch?.gender ? err['basicInfo.gender'] : ""}
              >
                <option disabled value="">Choose Option</option>
                {['Male', 'Female'].map(val => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nationality"
                placeholder="E.g Nigerian"
                name="basicInfo.nationality"
                type="text"
                required
                value={basicInfo.nationality}
                error={touch?.nationality ? err['basicInfo.nationality'] : ""}
              />
            </>
          )}
        </Column>
      </Row>
    </Column>
  );
};

export default BasicInfo;