import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import { useHMOs, useMeta } from 'src/state';
import { Column, Row, Input, Select, Paragraph } from 'src/components';
import { theme, addPatientValidation } from 'src/utils';
import { Patient } from 'src/types';
import { ModalSubTitle } from '../styled';

interface FormProps {
  isEditPatient: boolean;
}

type AddPatientValidation = typeof addPatientValidation;

const OtherInfo: React.FC<FormProps> = ({ isEditPatient }) => {

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors
  } = useFormikContext<Patient>();
  const { metaData } = useMeta();
  const { getIdentityTypes } = metaData;
  const { useHMOs: useHMOsData } = useHMOs();
  const { hmos, loading } = useHMOsData();
  const hmoData = hmos?.getHmos?.hmos
  const hmoTotal = hmos?.getHmos?.total
  const details = ['Single', 'Married', 'Divorced', 'Widow(er)', 'Separated', 'Engaged', 'Others']

  const { otherInfo, identity } = values;
  const { otherInfo: touch } = touched;
  const err = errors as AddPatientValidation;

  return (
    <Column justify="space-between" gap={1} align="center">
      <Column>
        <ModalSubTitle data-testid="section-title">
          Other Information
        </ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          Fill in the user information
        </Paragraph>
      </Column>
      <Row gap={1}>
        <Column gap={1} justify="space-between">
          {!isEditPatient && (
            <>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                required
                label="Date of Birth"
                placeholder="DD-MM-YYYY"
                name="otherInfo.dateOfBirth"
                max={moment().format('YYYY-MM-DD')}
                type="date"
                value={otherInfo.dateOfBirth}
                error={touch?.dateOfBirth ? err['otherInfo.dateOfBirth'] : ""}
              />
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                label="Marital Status"
                required
                name="otherInfo.maritalStatus"
                value={otherInfo.maritalStatus}
                error={touch?.maritalStatus ? err['otherInfo.maritalStatus'] : ""}
              >
                <option disabled value="">Choose Option</option>
                {details.map(val => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
            </>
          )}
          <Input
            label="Home Address"
            placeholder="12, Acme Street"
            name="otherInfo.homeAddress"
            type="text"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={otherInfo.homeAddress}
            error={touch?.homeAddress ? err['otherInfo.homeAddress'] : ""}
          />
          <Select
            onChange={handleChange}
            onBlur={handleBlur}
            label="Languages Spoken"
            name="otherInfo.languageSpoken"
            value={otherInfo.languageSpoken}
            error={touch?.languageSpoken ? err['otherInfo.languageSpoken'] : ""}
          >
            <option disabled value="">Choose Option</option>
            {['english', 'yoruba', 'hausa', 'igbo'].map(val => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </Select>
        </Column>
        <Column gap={1} justify="space-between">
          {!isEditPatient && (
            <>
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                label="Gender"
                name="otherInfo.gender"
                value={otherInfo.gender}
                required
                error={touch?.gender ? err['otherInfo.gender'] : ""}
              >
                <option disabled value="">Choose Option</option>
                {['Male', 'Female'].map(val => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                label="Nationality"
                placeholder="E.g Nigerian"
                name="otherInfo.nationality"
                type="text"
                required
                value={otherInfo.nationality}
                error={touch?.nationality ? err['otherInfo.nationality'] : ""}
              />
            </>
          )}
          <Input
            label="Secondary Phone Number (if any)"
            placeholder="E.g. 0801 234 5678"
            name="otherInfo.secondaryPhoneNumber"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={otherInfo.secondaryPhoneNumber?.replace(/\D/g, "")}
            error={touch?.secondaryPhoneNumber ? err['otherInfo.secondaryPhoneNumber'] : ""}
          />
          <Input
            label="Work Address"
            placeholder="12, Acme Street"
            name="otherInfo.workAddress"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={otherInfo.workAddress}
            error={touch?.workAddress ? err['otherInfo.workAddress'] : ""}
          />
        </Column>
      </Row>
      <Column>
        <ModalSubTitle>HMO Information</ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          Add Patient HMO information
        </Paragraph>
      </Column>
      <Row gap={1}>
        <Select
          onChange={handleChange}
          onBlur={handleBlur}
          label="HMO companies"
          name="otherInfo.hmo.hmo"
          loading={loading && hmoTotal === 0}
          disabled={loading && hmoTotal === 0}
          value={otherInfo?.hmo?.hmo || ""}
          error={touch?.hmo?.hmo ? err['otherInfo.hmo.hmo'] : ""}
        >
          <option disabled value="">Choose Option</option>
          {hmoTotal > 0 && hmoData?.map(val => (
            <option key={val?.id} value={val?.id}>
              {val.name}
            </option>
          ))}
        </Select>
        <Input
          label="Patient HMO ID"
          placeholder="000-000-000"
          name="otherInfo.hmo.hmoProviderId"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={otherInfo.hmo.hmoProviderId || ""}
          error={touch?.hmo?.hmoProviderId ? err['otherInfo.hmo.hmoProviderId'] : ""}
        />
      </Row>
      <Column>
        <ModalSubTitle>Identity</ModalSubTitle>
        <Paragraph color={theme.grey[500]}>
          Helps validate user’s information
        </Paragraph>
      </Column>
      <Row gap={1}>
        <Select
          onChange={handleChange}
          onBlur={handleBlur}
          label="Type Of Identity"
          name="identity.typeOfIdentity"
          value={identity?.typeOfIdentity}
          error={touched?.identity?.typeOfIdentity ? err['identity.typeOfIdentity'] : ""}
        >
          <option value="">Choose Option</option>
          {getIdentityTypes.map((val: string) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </Select>
        <Input
          label="Identity Number"
          placeholder="0000-0000-0000"
          name="identity.identityNumber"
          type="text"
          value={identity?.identityNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched?.identity?.identityNumber ? err['identity.identityNumber'] : ""}
        />
      </Row>
    </Column>
  );
};

export default OtherInfo;