import _ from 'lodash';
import { useState } from 'react';
import { CancelIcon } from 'src/assets/icons';
import {
  Button,
  Column,
  Row,
  Text,
  H6,
  Checkbox,
  InputLabel,
  SkeletonColumn,
  SearchInput
} from 'src/components';
import { Accordion, Modal } from 'src/containers';
import { usePerms, useStaff } from 'src/state';
import { removeModalHash } from 'src/utils/modalHelper';


const PermissionsModal: React.FC = () => {
  const { staff } = useStaff();
  const { usePermsissionsConfig } = usePerms();
  const [searchValue, setSearchValue] = useState<string>("");
  const { loading, permissionConfig } = usePermsissionsConfig();
  const defaultPermissions = staff.getStaff.permissions.map(({ id }) => id)

  const permissionValues = permissionConfig.filter(el => defaultPermissions.includes(el.id))
  const permissions = permissionValues
    .filter(el => !searchValue || el.name.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Modal width="52rem" modStyles={{ pt: 3.125 }} closeButton={false}>
      <Column gap={1}>
        <Row justify='space-between'>
          <Column gap={0.65}>
            <H6 color='#313131' modStyles={{ my: 0, lineHeight: '26.4px' }}>
              Your Permissions
            </H6> 
            <Text color="#666" size="sm" weight="semibold" modStyles={{ my: 0, lineHeight: '18.48px' }}>
              A total summary of your permissions.
            </Text>
          </Column>
          <Button width="max-content" onClick={removeModalHash} variant="text">
            <CancelIcon className='stroke-width-0' />
          </Button>
        </Row>

        <SearchInput 
          onChange={(ev) => setSearchValue(ev.target.value)} 
          placeholder='Search Permissions'
          autoFocus 
        />
        <Column 
          gap={1} 
          maxHeight='60vh' 
          modStyles={{ px: `0.25rem`, py: `0.25rem`, overflowY: `auto` }}
        >
          {loading && <SkeletonColumn width={690} height={49} />}
          {permissions.map(({ name, dependencies }, idx) => {
            const hasDependencies = dependencies.length > 0;
            const stringifyName = (value: string) => _.startCase(_.toLower(value));

            return (
              <Column key={idx} width="100%" justify="space-between" gap={1}>
                <Accordion openByDefault={false}>
                  <Accordion.Header hasArrowIcon={hasDependencies}>
                    <Row 
                      background='#F4F5F5' 
                      align="center" 
                      justify='space-between' 
                      modStyles={{ py: '0.875rem', px: '0.75rem', br: '0.625rem' }}
                    >
                      <Checkbox 
                        className='capitalize' 
                        checked
                        readOnly
                        label={name} 
                      />
                    </Row>
                  </Accordion.Header>
                  <Accordion.Content show={hasDependencies} className="br-10 shadow-accordion">
                    <Column gap={0.875}>
                      <Text 
                        weight='semibold' 
                        size="xs" 
                        color="#666666"
                        modStyles={{ lineHeight: '13.8px', my: 0 }}
                      >
                        Selecting &apos;{stringifyName(name)}&apos; grants access to the following permissions by default.
                      </Text>
                      {dependencies.map((el, index) => {
                        const { name: dependencyName, description } = el;
                        return (
                          <Row key={index} align="center">
                            <Checkbox 
                              id={dependencyName} 
                              readOnly 
                              background='#9B9B9B' 
                              border="1px solid #E5E5E5"
                              checked 
                            />
                            <InputLabel 
                              htmlFor={dependencyName}
                              modStyles={{ my: 0, fontSize: `0.875rem`, color: `#282828`, lineHeight: `18.48px` }}
                            >
                              {`${dependencyName} - ${description}`}
                            </InputLabel>
                          </Row>
                        )
                      })}
                    </Column>
                  </Accordion.Content>
                </Accordion>
              </Column>
            )
          })}
        </Column>
      </Column>
    </Modal>
  );
};
export default PermissionsModal;
